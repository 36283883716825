import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import ProductShow from "quorum/static/frontend/marketing-website/components/ProductShow"

import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"

const EU_PRODUCT_VALUES = {
    meta: {
        title: "European Union",
        description: "Centralize your team's advocacy with EU bill tracking and social media monitoring integrated into Quorum's public affairs software",
    },
    componentDetails: [
        {
            component: HeroImage,
            props: {
                imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/EU_banner.jpg"),
                title: "European Union",
                subtitle: "Meet Quorum EU, Europe’s Premier Public Affairs Platform.",
                backgroundPositionY: "30%",
                showEmailRequest: true,
                isDemoRequest: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "public-affairs-crm",
                key: "outbox",
                textAlignedLeft: true,
                theme: "section-grey",
                sectionMainHeader: "The World’s First Public Affairs CRM",
                sectionLeadHeader: "Keep Everyone On The Same Page",
                sectionText: "Seamlessly share information across your team with Quorum’s mobile app and interaction logger making it easy to track relationship history and upload meeting notes.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/eu/keep-everyone-on-same-page.png"),
                altText: "keep-everyone-on-same-page",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "database-legislative-information",
                key: "mobile-app",
                textAlignedLeft: false,
                sectionMainHeader: "The World’s Most Comprehensive Database of Legislative Information",
                sectionLeadHeader: "Never Miss A Mention",
                sectionText: "Make sure you never miss a mention of your issues or organisation by elected and appointed officials in their tweets, press releases,YouTube videos, parliamentary questions, speeches in plenary as well as EU legislation. ",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/eu/discover-connections.png"),
                altText: "discover-connections",

            },
        },
        {
            component: ProductShow,
            props: {
                id: "powerful-analyitcs",
                key: "legislative-tracking",
                textAlignedLeft: true,
                theme: "section-blue",
                sectionMainHeader: "Powerful Analytics To Inform Your Strategy",
                sectionLeadHeader: "Discover New Connections",
                sectionText: "Use data to inform your advocacy, whether it’s the number of meetings you took, which stakeholder is mentioning your issue the most, or how the volume of conversation compares over time.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/eu/never-miss-a-mention.png"),
                altText: "never-miss-a-mention",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "outbox",
                key: "spreadsheet-creator",
                textAlignedLeft: false,
                sectionMainHeader: "Integrated Email Tool",
                sectionLeadHeader: "Deliver Your Message",
                sectionText: "Quorum Outbox makes it easy to design and send emails to policy makers, their staff, and other stakeholders. Easily measure your engagement by tracking open rates, clicks, and more.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/eu/outbox-eu.png"),
                altText: "outbox-eu",
            },
        },
    ]
}

export default EU_PRODUCT_VALUES
