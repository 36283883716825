import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import ProductShow from "quorum/static/frontend/marketing-website/components/ProductShow"
import FeaturedArticle from "quorum/static/frontend/marketing-website/components/FeaturedArticle"
import urls from "shared/marketing-website/constants/urls"

import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"

const STAKEHOLDER_ENGAGEMENT_PAGE_VALUES = {
    meta: {
        title: "Stakeholder Engagement",
        description: "Monitor, communicate, and engage with your stakeholders, whoever they are.",
    },
    componentDetails: [
        {
            component: HeroImage,
            props: {
                backgroundPositionY: "50%",
                imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/stakeholder_banner.jpg"),
                subtitle: "Monitor, communicate, and engage with your stakeholders, whoever they are.",
                title: "Stakeholder Engagement Software",
                showEmailRequest: true,
                isDemoRequest: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "contact-management-database",
                key: "contact-management-database",
                textAlignedLeft: true,
                sectionMainHeader: "Easy-to-Use Contact Management Database",
                sectionLeadHeader: "Manage All of Your Relationships in One Place",
                sectionText: "Quorum provides a collaborative database to store contact information and track meetings with each of your stakeholders. Easily access the information on-the-go with Quorum’s mobile app and use the integrated business card scanner to seamlessly upload into Quorum.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/stakeholder_engagement/contact-management-database.png"),
                altText: "contact-management-database",
                secondaryCTA: {
                    link: "/resources/stakeholder-engagement-matrix-template/202/",
                    label: "Download Your Own Stakeholder Engagement Template"
                }
            },
        },
        {
            component: ProductShow,
            props: {
                id: "integrated-email-tool",
                key: "integrated-email-tool",
                textAlignedLeft: false,
                theme: "section-grey",
                sectionMainHeader: "Integrated Email Tool",
                sectionLeadHeader: "Personalize Your Communication",
                sectionText: "Keep your stakeholders up-to-date on the work your organization is doing by sending personalized emails through Quorum Outbox. Send beautifully designed emails without ever editing a line of HTML, or send plain-text emails with custom placeholders to add a personal touch.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/stakeholder_engagement/integrated-email-tool.png"),
                altText: "integrated-email-tool",
                secondaryCTA: {
                    label: "Learn How to Email Stakeholders with Quorum"
                }
            },
        },
        {
            component: ProductShow,
            props: {
                id: "comprehensive-dialogue-tracking",
                key: "comprehensive-dialogue-tracking",
                textAlignedLeft: true,
                theme: "section-blue",
                sectionMainHeader: "Comprehensive Dialogue Tracking",
                sectionLeadHeader: "Stay On Top Of The Conversation",
                sectionText: "Whether your stakeholders are elected officials, reporters, academics, influencers, or anyone in between, Quorum enables you to listen to what they are saying. Monitor Twitter, Facebook, Instagram, YouTube, and more to ensure that you never miss a mention of your organization or the issues you care about.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/stakeholder_engagement/comprehensive-dialogue-tracking.png"),
                altText: "comprehensive-dialogue-tracking",
                secondaryCTA: {
                    link: "/case-studies/building-relationships-policy-stakeholders/188/",
                    label: "See How Walmart Monitors the Conversations It Cares About"
                }
            },
        },
        {
            component: ProductShow,
            props: {
                id: "business-card-scanner",
                key: "business-card-scanner",
                textAlignedLeft: false,
                theme: "section-grey",
                sectionLeadHeader: "Save Time for What Matters",
                sectionMainHeader: "Business Card Scanner",
                sectionText: "Have a stack of business cards from your last conference? They only take two taps to add to Quorum—our fully automated business card scanner will create a new contact directly from your phone, complete with address, phone number, custom fields, and more.",
                videoSrc: "https://www.youtube.com/embed/EoyFKqi43mo?playlist=EoyFKqi43mo",
                videoClass: "mobile-video",
                backgroundImgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/mobile/iphone.png"),
                altText: "business-card-scanner",
                secondaryCTA: {
                    link: "/mobile-app/",
                    label: "Check out the Mobile App"
                }
            },
        },
        {
            component: FeaturedArticle,
            props: {
                id: "featured-products",
                key: "featured-products",
                title: "Featured Products",
                leadTitle: "Quorum’s integrated platform makes it easy to monitor, communicate, and engage with stakeholders across different regions including:",
                imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/main_banner.jpg"),
                external: false,
                productLinks: [
                    {
                        name: "Federal",
                        link: urls.federalProduct
                    },
                    {
                        name: "State",
                        link: urls.stateProduct
                    },
                    {
                        name: "Local",
                        link: urls.localProduct
                    },
                    {
                        name: "EU",
                        link: urls.euProduct
                    },
                    {
                        name: "International",
                        link: urls.internationalProduct
                    },
                ]
            },
        }
    ],
}

export default STAKEHOLDER_ENGAGEMENT_PAGE_VALUES
