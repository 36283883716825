import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import ProductShow from "quorum/static/frontend/marketing-website/components/ProductShow"

import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"

const GRASSROOTS_ADVOCACY_PAGE_VALUES = {
    meta: {
        title: "Grassroots Advocacy",
        description: "Quorum Grassroots includes a mobile optimized action center, advocate database, and email tool.",
    },
    componentDetails: [
        {
            component: HeroImage,
            props: {
                backgroundPositionY: "50%",
                imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/grassroots_banner.jpg"),
                subtitle: "Acquire, educate, and activate your advocates in one place so you can impact the issues you care about.",
                title: "Grassroots Advocacy Software",
                showEmailRequest: true,
                isDemoRequest: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "acquire-supporters",
                key: "acquire-supporters",
                textAlignedLeft: true,
                sectionMainHeader: "Acquire New Supporters",
                sectionLeadHeader: "Powerful Tools for Advocate Acquisition",
                sectionText: "Find new advocates where they are—grow your list with acquisition tools like custom sign-up forms, Facebook and LinkedIn lead ads, inbound texting, and integrations.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/grassroots/acquire.png"),
                altText: "acquire-supporters",
                secondaryCTA: {
                    link: "/best-practices/advocate-acquisition-grassroots-network/457/",
                    label: "How to Grow Your Grassroots Network"
                }
            }
        },
        {
            component: ProductShow,
            props: {
                id: "educate-advocates",
                key: "educate-advocates",
                textAlignedLeft: false,
                theme: "section-grey",
                sectionMainHeader: "Educate Your Advocates",
                sectionLeadHeader: "Keep Everyone Informed",
                sectionText: "Build a custom website or embed individual campaigns, sign-up forms, issue profiles, and more on your own website so that your advocates can learn about your organization, browse your issues, and make their voice heard all from one place.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/grassroots/educate.png"),
                altText: "educate-advocates",
                secondaryCTA: {
                    link: "/case-studies/grow-grassroots-network/322/",
                    label: "Learn How ASA Educates Advocates"
                }
            }
        },
        {
            component: ProductShow,
            props: {
                id: "drive-advocate-action",
                key: "drive-advocate-action",
                textAlignedLeft: true,
                theme: "section-blue",
                sectionMainHeader: "Drive Advocate Action",
                sectionLeadHeader: "Ensure a Seamless Advocate Experience",
                sectionText: "Drive more personalized communication by giving advocates a choice between different message copy, or allow them to edit specific sections of your message.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/grassroots/activate.png"),
                altText: "drive-advocate-action",
                secondaryCTA: {
                    link: "/case-studies/eroc-grassroots-activation-regulation/444/",
                    label: "See how EROC Drove 6,000 Actions"
                }
            }
        },
        {
            component: ProductShow,
            props: {
                id: "manage-advocates",
                key: "manage-advocates",
                textAlignedLeft: false,
                sectionMainHeader: "Manage Your Advocates",
                sectionLeadHeader: "Centralized Advocate Database",
                sectionText: "Centralize your lists of supporters in one place to easily sort, filter, and identify specific groups. Map your supporters by legislative district and level of engagement to coordinate targeted campaigns.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/grassroots/centralized_advocate_database.png"),
                altText: "manage-advocates",
                secondaryCTA: {
                    link: "/case-studies/legislative-advocacy-program-LTA-quorum/423/",
                    label: "Building an Advocacy Program with Quorum"
                }
            }
        },
        {
            component: ProductShow,
            props: {
                id: "make-emails-count",
                key: "make-emails-count",
                textAlignedLeft: true,
                theme: "section-purple",
                sectionMainHeader: "Make Your Emails Count",
                sectionLeadHeader: "Integrated Email Tool",
                sectionText: "No need to know HTML. Quorum Outbox makes it easy to design and send emails to key contacts, stakeholders, and advocates. Measure your impact by tracking open rates, clicks, and more.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/grassroots/integrated_email_tool.png"),
                altText: "make-emails-count",
                secondaryCTA: {
                    label: "Learn How to Email Advocates Through Quorum"
                }
            }
        },
        {
            component: ProductShow,
            props: {
                id: "engage-your-advocates",
                key: "engage-your-advocates",
                textAlignedLeft: false,
                sectionMainHeader: "Engage Your Advocates",
                sectionLeadHeader: "Grassroots Gamification",
                sectionText: "Motivate participation and loyalty by awarding your advocates points for taking action. Create engagement levels to easily identify and recognize your most active advocates while inspiring friendly competition.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/grassroots/engage-grassroots-gamification.png"),
                altText: "engage-your-advocates",
                secondaryCTA: {
                    label: "Learn More About Gamification"
                }
            }
        },
    ]
}

export default GRASSROOTS_ADVOCACY_PAGE_VALUES
