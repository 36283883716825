import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import ProductShow from "quorum/static/frontend/marketing-website/components/ProductShow"

import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"

const REGULATIONS_PRODUCT_VALUES = {
    meta: {
        title: "Regulations",
        description: "Easy-to-use tools to track state and federal regulations through their full lifecycle.",
    },
    componentDetails: [
        {
            component: HeroImage,
            props: {
                imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/regulations_banner2.jpg"),
                title: "Modernize Your Regulatory Tracking",
                subtitle: "Easy-to-use tools to track state and federal regulations through their full lifecycle.",
                backgroundPositionY: "15%",
                showEmailRequest: true,
                isDemoRequest: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "comprehensive-regulatory-dataset",
                key: "comprehensive-regulatory-dataset",
                textAlignedLeft: true,
                sectionMainHeader: "Comprehensive Regulatory Dataset",
                sectionLeadHeader: "Monitor the full lifecycle of a rule",
                sectionText: "See everything you need about a regulation on a single profile. Then follow that regulation to get alerted in real time whenever there’s a hearing scheduled, related document posted, or a status update with information from sources including state and federal registers, OIRA, OMB, the Unified Agenda, regulations.gov, and more.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/regulations/regs-profile.png"),
                altText: "comprehensive-regulatory-dataset",
            }
        },
        {
            component: ProductShow,
            props: {
                id: "revolutionary-tracking-system",
                key: "revolutionary-tracking-system",
                textAlignedLeft: false,
                theme: "section-grey",
                sectionMainHeader: "Revolutionary Tracking System",
                sectionLeadHeader: "A personalized tracking system designed for you",
                sectionText: "Whether you’re a one-person shop or part of a team, your experience with Quorum is tailored to you. Create a custom region of states you care about, set up a tracking board to automatically pull in relevant regulations, generate spreadsheets or charts automatically, and get alerts straight to your inbox.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/regulations/Regs-spreadsheet.001.png"),
                altText: "revolutionary-tracking-system",
            }
        },
        {
            component: ProductShow,
            props: {
                id: "standardized-and-searchable",
                key: "standardized-and-searchable",
                textAlignedLeft: true,
                theme: "section-blue",
                sectionMainHeader: "Standardized And Searchable",
                sectionLeadHeader: "A consistent experience across state and federal governments",
                sectionText: "Everything in Quorum is standardized and searchable—use keywords, phrases, or the title of a rule to search through state and federal regulations quickly, then generate reports that stay consistent across different states or regions.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/regulations/reg-search-2.png"),
                altText: "standardized-and-searchable",
            }
        },
        {
            component: ProductShow,
            props: {
                id: "fully-integrated-platform",
                key: "fully-integrated-platform",
                textAlignedLeft: false,
                sectionMainHeader: "Fully Integrated Platform",
                sectionLeadHeader: "Leverage powerful workflow tools in Quorum",
                sectionText: "Regulations, legislation, social media posts from agency heads, and official contact information integrate seamlessly with a professional suite of tools for report creation, bulk emails, grassroots campaigns, stakeholder engagement efforts, and more.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/regulations/agency-officials-search.001.png"),
                altText: "fully-integrated-platform",
            }
        },
    ],
}

export default REGULATIONS_PRODUCT_VALUES
