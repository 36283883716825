import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import ProductShow from "quorum/static/frontend/marketing-website/components/ProductShow"

import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"

const LOCAL_PRODUCT_VALUES = {
    meta: {
        title: "Local",
        description: "Centralize your team's advocacy with contact databases and website monitoring across 6,500 cities, all integrated into Quorum's public affairs software.",
    },
    componentDetails: [
        {
            component: HeroImage,
            props: {
                imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/local_banner_new.jpg"),
                title: "Local",
                subtitle: "Local Official Directory, Email Tool, and Social Media Monitoring.",
                backgroundPositionY: "25%",
                showEmailRequest: true,
                isDemoRequest: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "social-media-tracking",
                key: "social-media-tracking",
                textAlignedLeft: true,
                theme: "section-grey",
                sectionMainHeader: "Social Media Tracking",
                sectionLeadHeader: "Stay In The Know",
                sectionText: "Receive email alerts anytime a local official is talking about your issue or organization on Twitter, Facebook, Instagram, or YouTube.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/local/social_media_tracking_2.png"),
                altText: "social-media-tracking",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "local-government-contact-database",
                key: "local-government-contact-database",
                textAlignedLeft: false,
                sectionMainHeader: "Local Government Contact Database",
                sectionLeadHeader: "Contact Who You Need",
                sectionText: "Quickly find contact information for the officials who can impact your issue by filtering through our database of over 50,000 local officials including Mayors, City Councilmembers, County Legislators, Sheriffs, District Attorneys, Police Chiefs, City Administrators, and more.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/local/government_contact_database_2.png"),
                altText: "local-government-contact-database",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "interaction-logger",
                key: "interaction-logger",
                textAlignedLeft: true,
                theme: "section-blue",
                sectionMainHeader: "Interaction Logger",
                sectionLeadHeader: "Keep Track Of Your Meetings",
                sectionText: "Log your interactions with local elected officials the moment they happen. Share notes of your interactions across your team to ensure everyone is on the same page.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/local/interaction_logger_2.png"),
                altText: "interaction-logger",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "email-tools",
                key: "email-tools",
                textAlignedLeft: false,
                sectionMainHeader: "Integrated Email Tool",
                sectionLeadHeader: "Communicate Your Presence",
                sectionText: "Use Quorum Outbox to engage with local officials and send personalized updates promoting your organization’s impact in the community.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/local/integrated_email_tool_2.png"),

                altText: "email-tools",
            },
        },
    ]
}

export default LOCAL_PRODUCT_VALUES
