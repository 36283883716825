import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"

const CAREERS_PAGE_VALUES = {
    metaTitle: "Careers | Quorum",
    metaDescription: "From a dorm room to the nation’s capital, the most valuable ingredient to Quorum’s growth and success is our people. Hill staffers, techies, campaign vets, engineers, and everyone in between have joined us as we help organizations have a bigger impact on the issues that matter to them.",
    title: "Careers at Quorum",
    imgPath: generateStaticUrl("apps/marketing-website/images/quorum_office.jpg"),
    videoPath: generateStaticUrl("apps/marketing-website/images/careers/Quorum_B_Roll_Compress.mp4"),
    sections: {
        whyWorkAtQuorum: {
            header: "",
            text: "From a dorm room to the nation’s capital, the most valuable ingredient to Quorum’s growth and success is our people. Hill staffers, techies, campaign vets, engineers, and everyone in between have joined us as we help organizations have a bigger impact on the issues that matter to them.",
            buttonText: "Find a Job",
            images: [
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/careers/banner/communal_table.JPG"),
                    altText: "Quorum team members enjoy our open office floor plan with a communal lunch table.",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/careers/banner/conference_room.JPG"),
                    altText: "Quorum's conference room has glass walls, allowing for team members to write ideas and code on all surfaces using dry erase markers.",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/careers/banner/collaborative_environment.jpeg"),
                    altText: "Quorum employees enjoy a collaborate, open working environment.",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/careers/banner/glass_walls.JPG"),
                    altText: "Quorum employees using the glass walls of meeting spaces to sketch out ideas.",
                },
            ],
        },
        availablePositions: {
            header: "Available Positions",
            // Unfortunately need to specify the departments here if we want to be able to order the departments
            // Check https://api.greenhouse.io/v1/boards/quorum/departments for the list of department names
            departments: [
                "Software Development",
                "Customer Success",
                "Business Development",
                "Operations",
                "Marketing and Communications",
                "European Union",
            ],
            noAvailablePositionsText: "Quorum doesn’t have any roles open right now, but we’re always growing and will need more great people soon! Please check back to see if we’ve opened a role.",
            images: [
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/careers/banner/friday_chats.JPG"),
                    altText: "Quorum team members meet every Friday for 'chats' during which they recap the previous week's activity.",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/careers/banner/art_office.jpeg"),
                    altText: "Art lines the walls of Quorum's downtown Washington, D.C. office.",
                },
            ],
        },
        benefits: {
            header: "Benefits",
            items: [
                {
                    title: "Health, Dental, and Vision",
                    description: "When you're working hard, it's important to stay healthy and having good healthcare is part of that. We provide trans-inclusive health, dental, and vision coverage for team members and their dependents so that you can have peace of mind when it comes to your health.",
                    icon: "fa-heart",
                },
                {
                    title: "Parental Leave",
                    description: "We know that family is important. So, we support our team members’ goals of growing their families by offering generous paid maternity, paternity, adoption, and fostering leave.",
                    icon: "fa-users",
                },
                {
                    title: "Unlimited PTO and Flex Time",
                    description: "We trust our team to make good decisions when it comes to how and when they want to work. We don’t limit you to a particular number of days off or require you to be in the office—do what you need to be successful and productive.",
                    icon: "fa-clock-o",
                },
                {
                    title: "Free Meals at the Office",
                    description: "In our early startup days we lived together in a house and ate dinner together as a team every night. We’ve kept that culture alive as we’ve grown and provide breakfast, lunch, and dinner (plus lots of snacks!) at the office.",
                    icon: "fa-cutlery",
                },
                {
                    title: "Military Leave Policy",
                    description: "We appreciate and honor all military service. To support our service members, we offer a military leave policy and partner with community organizations to help veterans transition into careers in technology.",
                    icon: "fa-flag",
                },
                {
                    title: "Fitness Center",
                    description: "When it’s easier to stay fit it’s easier to stay healthy. Our office has a fully-stocked gym + showers, and, if you’re feeling ambitious, our biweekly “Quorum Fit” squad will have you out of breath in no time.",
                    icon: "fa-bicycle",
                },
                {
                    title: "Team Social Events",
                    description: "Trampoline dodgeball? Check. Apple picking in the fall? That too. Open-air double decker bus tours of DC with commentary provided by team members? A Quorum tradition. We believe that our team works together better because we have fun together.",
                    icon: "fa-futbol-o",
                },
                {
                    title: "And much more",
                    description: "401k match. Affinity groups. Commuter benefits. Disability insurance. Life insurance. Rideshare benefits. Credit union access. Weekly tech talks. Monthly happy hours. Employee Assistance Program (including Mental Health Counseling). Hearing Discount Program. Quorum is continually growing and our benefits are too.",
                    icon: "fa-smile-o",
                },
            ],
            images: [
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/careers/banner/startup_sounds.jpeg"),
                    altText: "A live band headlines 'Startup Sounds', a concert series hosted at Quorum's downtown Washington, D.C. office space.",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/careers/banner/provided_meals.JPG"),
                    altText: "Quorum employees enjoy fresh and healthy meals at the office, all provided by the company.",
                },
            ],
        },
        facilities: {
            header: "Facilities",
            text: "The Quorum team works out of a beautiful new office space in downtown Washington D.C. that is accessible to the blue, orange, silver, and red metro lines. Standing desks, casual seating areas, and a fully stocked kitchen are just a few of the perks that make our office a collaborative place to work.",
            images: [
                // {
                //     imgPath: "https://s3.amazonaws.com/quorum-static/static/img/careers/join_our_team/meeting_spaces.JPG",
                //     altText: "Quorum employees using one of many dedicated meeting spaces in the company's downtown Washington, D.C. office.",
                // },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/careers/banner/louie.JPG"),
                    altText: "A Quorum employee and Louie the dog, the namesake for one of the conference rooms in the company's downtown Washington, D.C. office.",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/careers/banner/standing_desk.jpeg"),
                    altText: "A Quorum employee using a standing desk in front of a chalk-art decorated wall.",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/careers/banner/kitchen.JPG"),
                    altText: "A tile map of the Washington, D.C. Metro system in the kitchen of Quorum's office.",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/careers/banner/working_environment.JPG"),
                    altText: "Quorum employees enjoy a collaborate, open working environment.",
                },
                {
                    imgPath: generateStaticUrl("apps/marketing-website/images/quorum_office.jpg"),
                    altText: "Quorum's office in downtown Washington, D.C.",
                },
            ],
        },
        joinOurTeam: {
            header: "",
            text: "Like what you see here? Want to learn more? Apply for one of our open roles and we’ll be in touch.",
            buttonText: "Join Our Team",
        },
    },
}

export default CAREERS_PAGE_VALUES
