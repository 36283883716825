import urls from "shared/marketing-website/constants/urls"

import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"

const HOME_PAGE_PRODUCT_VALUES = [
    {
        id: "stakeholder-engagement",
        sectionMainHeader: "Stakeholder Engagement",
        sectionMainHeaderUrl: urls.stakeholderEngagement,
        sectionText: "Quorum provides teams with a one-stop shop to monitor, communicate, and engage with their stakeholders.",
        // sectionQuote: `"Quorum has taken the inefficiencies out of our system and built a whole new muscle for our team in how we communicate efficiently and effectively."`,
        // sectionQuoteAuthor: "Gerard Dehrmann",
        // sectionQuoteAuthorTitle: "SVP Public Affairs, Walmart",
        // sectionQuoteLogo: generateStaticUrl("apps/marketing-website/images/client_logos/original/Walmart.png"),
        imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/main/stakeholder_engagement.png"),
        altText: "stakeholder-engagement",
        textAlignedLeft: true,
        theme: "section-grey",
        secondaryCTA: {
            link: "/stakeholder-engagement/",
            label: "Learn about Stakeholder Engagement with Quorum",
        }
    },
    {
        id: "legislative-tracking",
        sectionMainHeader: "Legislative Tracking",
        sectionMainHeaderUrl: urls.legislativeTracking,
        sectionText: "Track legislation, statements, and social media from elected officials in Congress, all 50 state legislatures, and the European Union.",
        imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/main/legislative_tracking.png"),
        altText: "legislative-tracking",
        theme: "section-blue",
        textAlignedLeft: false,
        specialStyles: {width: "100px"},
        secondaryCTA: {
            link: "/legislative-tracking/",
            label: "Learn about Legislative Tracking with Quorum"
        }
    },
    {
        id: "grassroots-advocacy",
        sectionMainHeader: "Grassroots Advocacy ",
        sectionMainHeaderUrl: urls.grassrootsAdvocacy,
        sectionText: "Use one software platform to manage lists of advocates or employees, send customized email calls to action, launch campaigns, and pull reports on actions taken. Quorum is easy to use for your advocates and your advocacy team.",
        // sectionQuote: `"The biggest impact [of Quorum] has been the growth of our ASA grassroots network. It’s become easier for our members to use and we have seen the number of participants actively increase."`,
        // sectionQuoteAuthor: "Amanda Ott",
        // sectionQuoteAuthorTitle: "Senior Governmental and Political Outreach Manager, ASA",
        // sectionQuoteLogo: generateStaticUrl("apps/marketing-website/images/client_logos/original/asa.png"),
        imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/main/grassroots_advocacy.png"),
        altText: "grassroots-advocacy",
        theme: "section-grey",
        textAlignedLeft: true,
        secondaryCTA: {
            link: "/grassroots-advocacy/",
            label: "Learn about Grassroots Advocacy with Quorum"
        }
    },
]

export default HOME_PAGE_PRODUCT_VALUES
