import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import ProductShow from "quorum/static/frontend/marketing-website/components/ProductShow"
import FeaturedArticle from "quorum/static/frontend/marketing-website/components/FeaturedArticle"

import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"

const FEDERAL_PRODUCT_VALUES = {
    meta: {
        title: "Federal",
        description: "Centralize your team's advocacy with congressional bill tracking and social media monitoring integrated into Quorum's public affairs software.",
    },
    componentDetails: [
        {
            component: HeroImage,
            props: {
                imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/federal_banner.jpg"),
                title: "Federal",
                subtitle: "Legislative and regulatory tracking, social media alerts, staff directory, and email tool.",
                backgroundPositionY: "5%",
                showEmailRequest: true,
                isDemoRequest: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "legislative-tracking",
                key: "legislative-tracking",
                textAlignedLeft: true,
                theme: "section-grey",
                sectionMainHeader: "Legislative Tracking",
                sectionLeadHeader: "Never Miss A Mention",
                sectionText: "Quorum’s advanced social media monitoring combined with the world’s most comprehensive database of legislative information will ensure you never miss a mention of your issues or organization by members of Congress in their bills, tweets, Facebook posts, YouTube videos, press releases, floor statements, emails to constituents, and more.",
                // https://s3.amazonaws.com/quorum-static/static/img/products/federal/federal_legislative_tracking.png
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/federal/legislative_tracking_2.png"),
                altText: "never-miss-a-mention",
                secondaryCTA: {
                    link: "/case-studies/crisis-communications-trump-tweet/147/",
                    label: "Respond to Trump's Tweets Like Toyota"
                }
            },
        },
        {
            component: FeaturedArticle,
            props: {
                id: "identifying-legislative-champion",
                key: "white-paper-1",
                leadTitle: "Featured Whitepaper",
                title: "7 Steps to Identifying a Legislative Champion",
                url: "/resources/finding-legislative-champions/137/",
                imgPath: "https://info.quorum.us/hubfs/washington-dc-85544_1920_1.jpg?t=1496079482749",
                external: false,
            },
        },
        {
            component: ProductShow,
            props: {
                id: "regulatory-data",
                key: "regulatory-data",
                textAlignedLeft: false,
                sectionMainHeader: "Regulatory Data",
                sectionLeadHeader: "Monitor the Full Lifecycle of a Rule",
                sectionText: "Quorum pulls documents and information from the unified agenda, OIRA, OMB, federal register, and public inspection desk so you stay on top of your regulatory issues throughout the entire rulemaking process.Every regulation has a standardized and comprehensive profile so you have every related piece of information in one place.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/regulations/regs-profile.png"),
                altText: "regulatory-data",
                secondaryCTA: {
                    link: "/regulations/",
                    label: "Learn More",
                }
            },
        },
        {
            component: ProductShow,
            props: {
                id: "mobile-app",
                key: "mobile-app",
                textAlignedLeft: true,
                theme: "section-purple",
                sectionMainHeader: "Mobile App",
                sectionLeadHeader: "Keep Track of Every Meeting",
                sectionText: "Log your meetings with members of Congress and staffers using Quorum’s mobile app to preserve institutional knowledge, easily manage compliance reports, and measure your impact.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/federal/mobile_app_2.png"),
                altText: "track-every-meeting",
                secondaryCTA: {
                    link: "/resources/measuring-government-affairs/139/",
                    label: "Learn How to Measure Your Impact"
                }
            },
        },
        {
            component: ProductShow,
            props: {
                id: "outbox",
                key: "outbox",
                textAlignedLeft: false,
                sectionMainHeader: "Staffer Directory and Integrated Email Tool",
                sectionLeadHeader: "Easily Contact Legislative Staff",
                sectionText: "Quickly identify staffers by issue, role, committee, or the member they work for. Save or download emails, phone numbers, and issue areas, or use Quorum Outbox to deliver personalized email messages to hundreds of staffers at once.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/federal/integrated_email_tool_2.png"),
                altText: "contact-legislative-staff",
                secondaryCTA: {
                    link: "/case-studies/legislative-monitoring-engagement/238/",
                    label: "See how Engine engages Congress"
                }
            },
        },
        {
            component: ProductShow,
            props: {
                id: "spreadsheet-creator",
                key: "spreadsheet-creator",
                textAlignedLeft: true,
                theme: "section-grey",
                sectionMainHeader: "Scorecard Creator",
                sectionLeadHeader: "Identify Key Insights",
                sectionText: "Create comprehensive scorecards outlining members’ voting records, legislative productivity, number of interactions with your staff, and more. Use data from the American Community Survey to compare and contrast district or state demographics across all members of Congress.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/federal/scorecard_creator_2.png"),

                altText: "identify-key-insights",
                secondaryCTA: {
                    link: "/case-studies/modernize-pac-management/146/",
                    label: "See How General Motors Uses Sheets"
                }
            },
        },
        {
            component: ProductShow,
            props: {
                id: "member-profiles",
                key: "member-profiles",
                textAlignedLeft: false,
                theme: "section-blue",
                sectionMainHeader: "Member Profiles",
                sectionLeadHeader: "Prepare For A Meeting In Minutes",
                sectionText: "Easily pull together custom biographies, social media mentions of your organization, and your organization’s previous interactions with members of Congress into a simple PDF download.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/federal/member_profiles_2.png"),
                altText: "prepare-for-meeting",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "legislator-analytics",
                key: "legislator-analytics",
                textAlignedLeft: true,
                sectionMainHeader: "Legislator Analytics",
                sectionLeadHeader: "Find Your Champions",
                sectionText: "Identify who members work with most frequently, what issues they are most active on, and how effective they are at getting legislation out of committee.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/federal/legislator_analytics_3.png"),
                altText: "find-your-champions",
                secondaryCTA: {
                    link: "/resources/finding-legislative-champions/137/",
                    label: "Use Data to Identify Champions"
                }
            },
        },
        {
            component: ProductShow,
            props: {
                id: "custom-data",
                key: "custom-data",
                textAlignedLeft: false,
                theme: "section-purple",
                sectionMainHeader: "Custom Data",
                sectionLeadHeader: "Communicate Your Organization's Footprint",
                sectionText: "Map your facilities, members, employees, and suppliers to every congressional district in the country. Easily access this information on-the-go with Quorum Mobile or share it in a customized PDF report.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/federal/custom_data_2.png"),
                altText: "communicate-your-footprint",
                secondaryCTA: {
                    link: "/resources/custom-data/138/",
                    label: "Leverage the Power of your Organization's Data"
                }
            },
        },
        {
            component: ProductShow,
            props: {
                id: "federal-committee-hearings",
                key: "federal-committee-hearings",
                textAlignedLeft: true,
                sectionMainHeader: "Federal Committee Hearings",
                sectionLeadHeader: "Track Your Issues By Committee",
                sectionText: "Work smarter by tracking your issues by committee. Get alerts related to your issue from every congressional hearing (for all 45 committees and their subcommittees) within 60 minutes of a hearing's conclusion.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/federal/comm-hearings2.png"),
                altText: "communicate-your-footprint",
            },
        },
    ]
}

export default FEDERAL_PRODUCT_VALUES
