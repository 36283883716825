import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import ProductShow from "quorum/static/frontend/marketing-website/components/ProductShow"
import AddOns from "quorum/static/frontend/marketing-website/components/AddOns"
import urls from "shared/marketing-website/constants/urls"

import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"

const PRODUCTS_PAGE_VALUES = {
    meta: {
        title: "Products",
        description: "Quorum offers six distinct products across stakeholder engagement, legislative tracking, and grassroots advocacy.",
    },
    componentDetails: [
        {
            component: HeroImage,
            props: {
                backgroundPositionY: "50%",
                imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/main_banner.jpg"),
                subtitle: "Quorum is the only fully integrated public affairs software platform.",
                title: "Products",
                showEmailRequest: true,
                isDemoRequest: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "federal",
                key: "federal",
                textAlignedLeft: true,
                sectionMainHeader: "Quorum Federal",
                sectionMainHeaderUrl: urls.federalProduct,
                sectionLeadHeader: "Monitor and Engage Your Champions in Congress",
                sectionText: "Track congressional bills and monitor social media, House Dear Colleague letters, press releases, floor statements, and more. Quorum’s comprehensive congressional staff directory and email tool make it easy to quickly deliver personalized messages to hundreds of staffers at once. Use Quorum’s mobile app to track your meetings with each office.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/products/quorum_federal.png"),
                altText: "federal",
                secondaryCTA: {
                    link: "/products/federal/",
                    label: "Learn More About Quorum Federal"
                }
            }
        },
        {
            component: ProductShow,
            props: {
                id: "state",
                key: "state",
                textAlignedLeft: false,
                theme: "section-grey",
                sectionMainHeader: "Quorum State",
                sectionMainHeaderUrl: urls.stateProduct,
                sectionLeadHeader: "Modern 50-State Bill Tracking",
                sectionText: "Set up email alerts and neatly organized projects for bills, social media, press releases, and more. Quickly deliver personalized emails to hundreds of legislators at once and access information about bills and legislators on-the-go with Quorum’s mobile app.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/products/quorum_states.png"),
                altText: "state",
                secondaryCTA: {
                    link: "/products/state/",
                    label: "Learn More About Quorum State"
                }
            }
        },
        {
            component: ProductShow,
            props: {
                id: "local",
                key: "local",
                textAlignedLeft: true,
                theme: "section-blue",
                sectionMainHeader: "Quorum Local",
                sectionMainHeaderUrl: urls.localProduct,
                sectionLeadHeader: "Follow Local Officials",
                sectionText: "Keep an ear to the ground with Quorum Local. Never miss a mention when a local official is talking about your issue or organization on Facebook or Twitter. Search 50,000+ local officials to access contact information for relevant mayors, city council members, and county officials to know who to work with in thousands of cities across the country.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/products/quorum_local.png"),
                altText: "local",
                secondaryCTA: {
                    link: "/products/local/",
                    label: "Learn More About Quorum Local"
                }
            }
        },
        {
            component: ProductShow,
            props: {
                id: "eu",
                key: "eu",
                textAlignedLeft: false,
                theme: "section-grey",
                sectionMainHeader: "Quorum EU",
                sectionMainHeaderUrl: urls.euProduct,
                sectionLeadHeader: "Monitor Institutions in the EU",
                sectionText: "Follow the activities of the European Parliament, Commission, and Council with Quorum EU. Set up email alerts to ensure you never miss a mention of your issue or organisation by EU officials across legislation, press releases, social media, and more.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/products/quorum_eu.png"),
                altText: "eu",
                secondaryCTA: {
                    link: "/products/eu/",
                    label: "Learn More About Quorum EU"
                }
            }
        },
        {
            component: ProductShow,
            props: {
                id: "grassroots",
                key: "grassroots",
                textAlignedLeft: true,
                theme: "section-purple",
                sectionMainHeader: "Quorum Grassroots",
                sectionMainHeaderUrl: urls.grassrootsAdvocacy,
                sectionLeadHeader: "Empower Your Advocates",
                sectionText: "Lower the barrier for advocates to take action with simple registration and combined campaigns that send multiple unique actions, all with one click. An integrated contact management system, email tool, and action center empower you to more easily manage your advocacy program.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/products/quorum-grassroots.png"),
                altText: "grassroots",
                secondaryCTA: {
                    link: "/grassroots-advocacy/",
                    label: "Learn More About Quorum Grassroots"
                }
            }
        },
        {
            component: ProductShow,
            props: {
                id: "stakeholder-engagement",
                key: "stakeholder-engagement",
                textAlignedLeft: false,
                sectionMainHeader: "Quorum Stakeholder",
                sectionMainHeaderUrl: urls.stakeholderEngagement,
                sectionLeadHeader: "Activate Your Relationships",
                sectionText: "Quorum provides teams with a one-stop shop to monitor, communicate, and engage with their third-party stakeholders. Manage your contacts in a centralized database and send them updates on the work your organization is doing with an integrated email tool.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/products/quorum_stakeholder_engagement.png"),
                altText: "stakeholder-engagement",
                secondaryCTA: {
                    link: "/stakeholder-engagement/",
                    label: "Learn More About Quorum Stakeholder"
                }
            }
        },
        {
            component: ProductShow,
            props: {
                id: "international",
                key: "international",
                textAlignedLeft: true,
                theme: "section-grey",
                sectionMainHeader: "Quorum International",
                sectionMainHeaderUrl: urls.internationalProduct,
                sectionLeadHeader: "Work together better to achieve your goals",
                sectionText: "Gather intelligence, identify trends, and communicate with key stakeholders so your global public affairs team can work together better to achieve your goals.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/international/international_product.png"),
                altText: "international",
                secondaryCTA: {
                    link: "/products/international/",
                    label: "Learn More About Quorum International"
                }
            }
        },
        {
            component: AddOns,
            props: {
                description: "At Quorum, we have built additional features that help clients accomplish specific objectives that can be added on to our products above.",
                addOns: [
                    {
                        title: "Stakeholder Social Media Tracking",
                        description: "Monitor what your stakeholders are saying on social media, and create email alerts to notify you the moment they mention issues you care about.",
                        icon: "fa-comments",
                    },
                    {
                        title: "Ambassador Add-On",
                        description: "Build an institutional knowledge base from every interaction your key contacts have with elected officials by capturing that information seamlessly. Find out about pre-existing relationships that your key contacts have with elected officials by surveying members, advocates, or employees.",
                        icon: "fa-users",
                    },
                    {
                        title: "Custom Data",
                        description: "Elected officials consistently ask how a particular issue impacts their district. Format your organization's data across federal and state legislative districts and create powerful leave-behinds that communicate the story behind your data.",
                        icon: "fa-map",
                    },
                    {
                        title: "Integrations",
                        description: "We have clients push information to and from Quorum from CRMs like Salesforce, HR systems like Workday, association management systems like iMIS, Blackbaud, and Personify, and much more. We help clients centralize their data in one place so they can do their work more easily.",
                        icon: "fa-plug",
                    },
                ],
            }
        },
    ],
}

export default PRODUCTS_PAGE_VALUES
