import React from "react"

import PropTypes from "prop-types"
import classNames from "classnames"

import { Row, Col } from "react-bootstrap"
import EmailRequest from "quorum/static/frontend/marketing-website/components/EmailRequest"
import { MOBILE_WIDTH } from "quorum/static/frontend/marketing-website/constants/functions"
import { generateStaticUrl, generateMediaUrl } from "shared/imports/sharedHelperFunctions"
import MARKETING_SITE_VALUES from "shared/marketing-website/constants/marketingSiteValues"
import PaidSearchDemoForm from "quorum/static/frontend/marketing-website/components/PaidSearchDemoForm"

import "quorum/static/frontend/marketing-website/stylus/HeroImage.styl"

const mobileCTAEventTracking = device =>
    () => {
        gtag('event', window.location.pathname, {
            event_category: 'App Download Click',
            event_label: device
        })
    }

const HeroImage = ({
    children,
    title,
    subtitle,
    imgPath,
    videoPath,
    checklistHeader,
    checklistItems,
    customStyleClassName,
    backgroundPositionY,
    logoImgPath,
    showEmailRequest,
    isDemoRequest,
    mobileCTAs,
    fullHeaderRequestForm,

    // Blog detail props
    blogDetailPost,
    ctaButtonText,
    formHeader,
    gatedContentUrl,
    slug,
    whitePaperLink,
    }) => (
    <div
        className={classNames(
            `hero-img ${customStyleClassName}`,
            { "full-header-request-form": fullHeaderRequestForm }
        )}
        style={{
            backgroundImage: `url("${imgPath}")`,
            backgroundSize: "cover",
            backgroundPositionX: "center",
            backgroundPositionY,
        }}
    >
        {
            videoPath &&
            // Do not render video tag on mobile devices
            // A lot of data to load and video autoplays on fullscreen
            window.innerWidth > MOBILE_WIDTH &&
            <div
                className="hero-img-video-wrapper"
            >
                <video
                    className="hero-img-video"
                    poster={generateMediaUrl(imgPath)}
                    autoPlay
                    loop
                    muted
                >
                    <source src={videoPath} />
                </video>
            </div>
        }
        <div
            className={classNames(
                "hero-img-container",
                { "full-header-request-form": fullHeaderRequestForm }
            )}
        >
            <div
                className="hero-img-centered-content fade-in-from-right"
            >
                <Row
                    className="hero-img-centered-content-row"
                >
                    <Col
                        lg={10}
                        lgOffset={1}
                        md={10}
                        mdOffset={1}
                        sm={10}
                        smOffset={1}
                        xs={12}
                        xsOffset={0}
                        className={classNames(
                            "hero-img-content-items-wrapper",
                            {
                                "full-header-request-form": fullHeaderRequestForm,
                                "blog-download-content-form": blogDetailPost,
                            }
                        )}
                    >
                        <Row
                            className={classNames(
                                "hero-img-content-items-row",
                                { "full-header-request-form": fullHeaderRequestForm }
                            )}
                        >
                            <Col
                                md={fullHeaderRequestForm ? 6 : 12}
                                className={classNames(
                                    "hero-img-content-items",
                                    { "full-header-request-form": fullHeaderRequestForm }
                                )}
                            >
                                <h1
                                    className={classNames(
                                        "title",
                                        { "full-header-request-form": fullHeaderRequestForm }
                                    )}
                                >
                                    {title}
                                </h1>
                                <h2
                                    className={classNames(
                                        "subtitle",
                                        { "full-header-request-form": fullHeaderRequestForm }
                                    )}
                                    dangerouslySetInnerHTML={{ __html: subtitle }}
                                />
                                {
                                    checklistHeader && checklistItems.length &&
                                    <div className="checklist-wrapper">
                                        <div className="checklist-header">
                                            {checklistHeader}
                                        </div>
                                        <div className="checklist-items-wrapper">
                                            {
                                                checklistItems.map(item => (
                                                    <div className="checklist-item"><i className="fa fa-check-square-o" />{item}</div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    generateMediaUrl(logoImgPath) &&
                                    <img
                                        src={generateMediaUrl(logoImgPath)}
                                        alt={title}
                                        className="hero-image-logo"
                                    />
                                }
                                {
                                    showEmailRequest &&
                                    <Row className="email-request-form hero-image">
                                        <Col
                                            lg={8}
                                            lgOffset={0}
                                            md={10}
                                            mdOffset={0}
                                            sm={10}
                                            smOffset={0}
                                            xsHidden
                                        >
                                            <EmailRequest
                                                bsSize="large"
                                                label="Header"
                                                header
                                                isDemoRequest={isDemoRequest}
                                                formKey={isDemoRequest ? "requestDemoForm" : undefined}
                                                formUrl={isDemoRequest ? "/submit_request_demo_form/" : undefined}
                                            />
                                        </Col>
                                        <Col
                                            lgHidden
                                            mdHidden
                                            smHidden
                                            xs={12}
                                            xsOffset={0}
                                        >
                                            <EmailRequest
                                                bsSize="large"
                                                label="Header"
                                                header
                                                shortPlaceholder
                                                isDemoRequest={isDemoRequest}
                                                formKey={isDemoRequest ? "requestDemoForm" : undefined}
                                                formUrl={isDemoRequest ? "/submit_request_demo_form/" : undefined}
                                            />
                                        </Col>
                                    </Row>
                                }
                                {
                                    mobileCTAs &&
                                    <Row className="mobile-cta-wrapper">
                                        <Col
                                            lg={8}
                                            lgOffset={0}
                                            md={10}
                                            mdOffset={0}
                                            sm={10}
                                            smOffset={0}
                                            xs={12}
                                            xsOffset={0}
                                            className="mobile-cta-column"
                                        >
                                            <a
                                                href={MARKETING_SITE_VALUES.appStoreLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={mobileCTAEventTracking("iPhone")}
                                            >
                                                <img
                                                    className="mobile-cta-app-store"
                                                    alt="app-store"
                                                    src={generateStaticUrl("apps/marketing-website/images/app_store_badge.svg")}
                                                />
                                            </a>
                                            <a
                                                href={MARKETING_SITE_VALUES.googlePlayStoreLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={mobileCTAEventTracking("Android")}
                                            >
                                                <img
                                                    className="mobile-cta-google-play"
                                                    alt="google-play"
                                                    src={generateStaticUrl("apps/marketing-website/images/google_play_badge.png")}
                                                />
                                            </a>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                            {
                                fullHeaderRequestForm &&
                                <PaidSearchDemoForm
                                    blogDetailPost={blogDetailPost}
                                    ctaButtonText={ctaButtonText}
                                    formHeader={formHeader}
                                    formKey={blogDetailPost ? 'blogDetailForm' : 'requestDemoForm'}
                                    formUrl={blogDetailPost ? '/submit_blog_detail_post_form/' : '/submit_request_demo_form/'}
                                    gatedContentUrl={gatedContentUrl}
                                    slug={slug}
                                    whitePaperLink={whitePaperLink}
                                />
                    }
                        </Row>
                    </Col>
                </Row>
                {children}
            </div>
        </div>
    </div>
)

HeroImage.defaultProps = {
    title: "",
    imgPath: "",
    videoPath: "",
    subtitle: "",
    checklistHeader: "",
    checklistItems: [],
    customStyleClassName: "",
    backgroundPositionY: "50%",
    logoImgPath: "",
    showEmailRequest: false,
    isDemoRequest: false,
    fullHeaderRequestForm: false,
    mobileCTAs: false,
    blogDetailPost: false,
    ctaButtonText: "",
    formHeader: "",
    gatedContentUrl: "",
    slug: "",
    whitePaperLink: "",
}

HeroImage.propTypes = {
    title: PropTypes.string,
    imgPath: PropTypes.string,
    videoPath: PropTypes.string,
    subtitle: PropTypes.string,
    checklistHeader: PropTypes.string,
    checklistItems: PropTypes.array,
    customStyleClassName: PropTypes.string,
    backgroundPositionY: PropTypes.string,
    children: PropTypes.object,
    logoImgPath: PropTypes.string,
    showEmailRequest: PropTypes.bool,
    isDemoRequest: PropTypes.bool,
    fullHeaderRequestForm: PropTypes.bool,
    mobileCTAs: PropTypes.bool,
    blogDetailPost: PropTypes.bool,
    ctaButtonText: PropTypes.string,
    formHeader: PropTypes.string,
    gatedContentUrl: PropTypes.string,
    slug: PropTypes.string,
    whitePaperLink: PropTypes.string,
}

export default HeroImage
