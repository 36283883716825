import urls from "shared/marketing-website/constants/urls"
import BlogType from "shared/marketing-website/constants/blogType"

import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"

const RESOURCES_PAGE_VALUES = {
    title: "Resources",
    imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/resources.jpg"),
    metaDescription: "Quorum's resources offer the information you need to impact the issues you care about.",
    subtitle: "The information you need to impact the issues you care about.",
    backgroundPositionY: "30%",
    // Some filters may be commented out because MarComms wants to hide those filter buttons
    filters: [
        {
            label: "All",
            value: undefined,
            subtitle: "View all content",
            url: urls.resources,
            urlPattern: undefined,
        },
        // BlogType.behind_the_desk,
        BlogType.best_practices,
        BlogType.case_studies,
        // BlogType.productivity,
        BlogType.data_driven_politics,
        // BlogType.public_affairs_dictionary,
        BlogType.white_papers,
    ],
}

export default RESOURCES_PAGE_VALUES
