import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"

const PRESS_VALUES = {
    title: "Press Gallery",
    subtitle: "Read about Quorum and its political data findings in the news",
    metaTitle: "Press | Quorum",
    metaDescription: "Read about Quorum and its political data findings in the news",
    imgPath: generateStaticUrl("apps/marketing-website/images/hero_images/resized/press_banner.jpg"),
    pressLogoImgPaths: {
        albq: generateStaticUrl("apps/marketing-website/images/media-imgs/Albuquerque-Journal.png"),
        axios: generateStaticUrl("apps/marketing-website/images/media-imgs/Axios.png"),
        bloomberg: generateStaticUrl("apps/marketing-website/images/media-imgs/Bloomberg-Politics.png"),
        "bloomberg-businessweek": generateStaticUrl("apps/marketing-website/images/media-imgs/Bloomberg-Businessweek.png"),
        bostinno: generateStaticUrl("apps/marketing-website/images/media-imgs/BostInno.png"),
        cbs: generateStaticUrl("apps/marketing-website/images/media-imgs/CBS.png"),
        crimson: generateStaticUrl("apps/marketing-website/images/media-imgs/Crimson-large.png"),
        cyberscoop: generateStaticUrl("apps/marketing-website/images/media-imgs/Cyberscoop.png"),
        dcinno: generateStaticUrl("apps/marketing-website/images/media-imgs/DCInno.png"),
        fedscoop: generateStaticUrl("apps/marketing-website/images/media-imgs/FedScoop.png"),
        forbes: generateStaticUrl("apps/marketing-website/images/media-imgs/Forbes.png"),
        gazette: generateStaticUrl("apps/marketing-website/images/media-imgs/Gazette.png"),
        gvh: generateStaticUrl("apps/marketing-website/images/media-imgs/Global-Voice-Hall.png"),
        huffingtonpost: generateStaticUrl("apps/marketing-website/images/media-imgs/Huffington-Post.png"),
        msnbc: generateStaticUrl("apps/marketing-website/images/media-imgs/MSNBC.png"),
        mashable: generateStaticUrl("apps/marketing-website/images/media-imgs/Mashable.png"),
        nationaljournal: generateStaticUrl("apps/marketing-website/images/media-imgs/National-Journal.png"),
        newmexican: generateStaticUrl("apps/marketing-website/images/media-imgs/New-Mexican.png"),
        newyorktimes: generateStaticUrl("apps/marketing-website/images/media-imgs/New-York-Times.png"),
        politico: generateStaticUrl("apps/marketing-website/images/media-imgs/Politico.png"),
        techcrunch: generateStaticUrl("apps/marketing-website/images/media-imgs/TechCrunch.png"),
        time: generateStaticUrl("apps/marketing-website/images/media-imgs/TIME.png"),
        thehill: generateStaticUrl("apps/marketing-website/images/media-imgs/The-Hill.png"),
        washingtonian: generateStaticUrl("apps/marketing-website/images/media-imgs/Washingtonian.png"),
        washingtonpost: generateStaticUrl("apps/marketing-website/images/media-imgs/Washington-Post.png"),
        vox: generateStaticUrl("apps/marketing-website/images/media-imgs/Vox.png"),
    },
    pressPieces: [
        {
            url: "https://www.bloomberg.com/news/articles/2018-01-10/lobbyists-have-a-new-secret-weapon",
            logo: "bloomberg-businessweek",
            publication: "Bloomberg Businessweek",
            title: "Lobbyists Have a New Secret Weapon",
        },
        {
            url: "https://www.washingtonpost.com/news/the-fix/wp/2018/05/17/who-will-decide-which-party-controls-the-house-whiter-wealthier-americans/?noredirect=on&utm_term=.8dbf9d302a1c",
            logo: "washingtonpost",
            publication: "Washington Post",
            title: "Who will decide which party controls the House? Whiter, wealthier Americans.",
        },
        {
            url: "http://thehill.com/blogs/floor-action/house/362036-women-democrats-leading-sexual-harassment-discussion-in-congress",
            logo: "thehill",
            publication: "The Hill",
            title: "Women, Dems leading sexual harassment discussion in Congress: analysis",
        },
        {
            url: "https://www.axios.com/congress-favorite-hashtag-in-2017-1512649523-0483c93b-45ae-423b-a5a9-3d5a37214c82.html",
            logo: "axios",
            publication: "Axios",
            title: "Congress' favorite hashtag in 2017: #trumpcare",
        },
        {
            url: "https://www.washingtonian.com/2016/10/30/nerdiest-group-house-washington-quorum-big-data/",
            logo: "washingtonian",
            publication: "Washingtonian",
            title: "The Residents in DC’s Nerdiest Group House Want to Shake Up Washington’s Oldest Trade",
        },
        {
            url: "http://www.washingtonpost.com/business/capitalbusiness/the-moneyball-effect-on-k-street-the-influence-game-gets-scientific/2015/03/12/4ab365f2-b14e-11e4-854b-a38d13486ba1_story.html",
            logo: "washingtonpost",
            publication: "Washington Post",
            title: "The ‘Moneyball’ effect on K Street: The Influence Game Gets Scientific",
        },
        {
            url: "http://www.nytimes.com/2016/01/05/upshot/how-you-can-measure-a-senator-as-a-presidential-contender.html?_r=0",
            logo: "newyorktimes",
            publication: "The New York Times",
            title: "How You Can Measure a Senator as a Presidential Contender",
        },
        {
            url: "http://thehill.com/blogs/floor-action/house/302533-vulnerable-gop-lawmakers-outpace-other-freshmen-in-productivity",
            logo: "thehill",
            publication: "The Hill",
            title: "Vulnerable House freshmen passed most bills in decades, analysis finds",
        },
        {
            url: "https://www.cyberscoop.com/texas-cybersecurity-legislation-michael-mccaul-john-ratcliffe-will-hurd/",
            logo: "cyberscoop",
            publication: "Cyberscoop",
            title: "This state is becoming America’s factory for cybersecurity legislation",
        },
        {
            url: "http://www.nationaljournal.com/magazine/saying-no-to-the-slog-20150522",
            logo: "nationaljournal",
            publication: "National Journal",
            title: "Saying No to the Slog: How Two Harvard Students Changed Lobbying",
        },
        {
            url: "http://www.huffingtonpost.com/entry/quorum-analytics-google-for-congress_562e82d8e4b06317990ee3e6",
            logo: "huffingtonpost",
            publication: "Huffington Post",
            title: "This Startup Is Like Google For Congress, And Legislators Love It",
        },
        {
            url: "http://www.politico.com/tipsheets/politico-influence/2015/10/whats-next-on-gun-control-vw-hires-jones-day-210523",
            logo: "politico",
            publication: "POLITICO Influence",
            title: "What's Next on Gun Control?",
        },
        {
            url: "http://www.cbsnews.com/news/claire-mccaskill-amy-klobuchar-kelly-ayotte-shelley-moore-capito-women-senate/",
            logo: "cbs",
            publication: "CBS This Morning",
            title: "Female senators upending the old boys' club",
        },
        {
            url: "http://www.bloomberg.com/politics/articles/2015-02-20/the-numbers-don-t-lie-women-make-more-effective-legislators-than-men",
            logo: "bloomberg",
            publication: "Bloomberg Politics",
            title: "The Numbers Don’t Lie: Women Make More Effective Legislators Than Men",
        },
        {
            url: "http://www.msnbc.com/rachel-maddow-show/watch/female-senators-perform-job-better-study-401713731892",
            logo: "msnbc",
            publication: "The Rachel Maddow Show",
            title: "Female Senators Perform Job Better: Study",
        },
        {
            url: "http://news.harvard.edu/gazette/story/2015/02/making-sense-of-congress/",
            logo: "gazette",
            publication: "Harvard Gazette",
            title: "Making Sense of Congress",
        },
        {
            url: "http://fedscoop.com/how-a-big-data-startup-is-changing-the-legislative-process?utm_content=buffer578e5&utm_medium=social&utm_source=twitter.com&utm_campaign=buffer",
            logo: "fedscoop",
            publication: "FedScoop",
            title: "How a Big Data Startup is Changing the Legislative Process",
        },
        {
            url: "http://thehill.com/blogs/ballot-box/237172-ted-cruzs-senate-record-by-the-numbers",
            logo: "thehill",
            publication: "The Hill",
            title: "Ted Cruz's Senate record by the numbers",
        },
        {
            url: "http://www.vox.com/2015/9/13/9310733/bipartisanship-chart",
            logo: "vox",
            publication: "Vox",
            title: "Bipartisan legislation is making a comeback",
        },
        {
            url: "http://time.com/4059777/oregon-shooting-house-senate-gun/",
            logo: "time",
            publication: "TIME",
            title: "How Congress Reacts To Mass Shootings In One Chart",
        },
        {
            url: "http://mashable.com/2015/02/19/female-legislators-more-effective/",
            logo: "mashable",
            publication: "Mashable",
            title: "Want to end partisan gridlock? Elect more women.",
        },
        // {
        //     url: "http://techcrunch.com/2015/02/10/goodbye-intern-labor-hi-quorum/",
        //     logo: "techcrunch",
        //     publication: "TechCrunch",
        //     title: "Harvard Innovation Challenge Winner Quorum Hopes To Replace Gaggles Of Interns In DC With Data-Driven Policy",
        // },
    ],
}

export default PRESS_VALUES
