import HeroImage from "quorum/static/frontend/marketing-website/components/HeroImage"
import ProductShow from "quorum/static/frontend/marketing-website/components/ProductShow"

import { generateStaticUrl } from "shared/imports/sharedHelperFunctions"

const MOBILE_EU_PRODUCT_VALUES = {
    meta: {
        title: "Download Quorum for Mobile",
        description: "With Quorum Mobile, you can take all their favorite Quorum functionality on the go. Download today.",
    },
    componentDetails: [
        {
            component: HeroImage,
            props: {
                imgPath: generateStaticUrl("apps/marketing-website/images/screenshots/mobile/new_mobile_app.jpg"),
                title: "Quorum Mobile",
                subtitle: "Take all of Quorum's features with you on the go",
                backgroundPositionY: "50%",
                mobileCTAs: true,
            }
        },
        {
            component: ProductShow,
            props: {
                id: "syncing-with-quorum",
                key: "syncing-with-quorum",
                textAlignedLeft: true,
                theme: "section-grey",
                mobilePage: true,
                sectionLeadHeader: "Stay in Sync, No Matter Where You Are",
                sectionMainHeader: "Fully Integrated Solution",
                sectionText: "Quorum Mobile gives you a seamless look at everything you manage in Quorum. Your notes, assignments, lists, custom bios, stances, and more sync directly with the site, and everything in Quorum is fully searchable in the mobile app. You're never more than a tap away from your data.",
                imgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/mobile/iphone-android-integrated-with-quorum.png"),
                altText: "syncing-with-quorum",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "log-interaction",
                key: "log-interaction",
                textAlignedLeft: false,
                mobilePage: true,
                sectionLeadHeader: "Manage Engagement",
                sectionMainHeader: "Log Interactions From The App",
                sectionText: "Use the Quorum mobile app to easily keep track of your meetings with legislators, staffers, or other stakeholders. Look back through to quickly find the last time someone from your team met with an office and what they talked about.",
                // Videos should be the youtube embed link
                // The playlist parameter should be set to the same video id to allow the video to loop
                // The other parameters will be set in the product show component
                videoSrc: "https://www.youtube.com/embed/qPO49zWkBUA?playlist=qPO49zWkBUA",
                videoClass: "mobile-xr-video",
                backgroundImgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/mobile/iphone-xr.png"),
                altText: "log-interaction",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "prep-for-meeting",
                key: "prep-for-meeting",
                textAlignedLeft: true,
                mobilePage: true,
                theme: "section-blue",
                sectionLeadHeader: "Prep for a Meeting",
                sectionMainHeader: "Have The Information You Need",
                sectionText: "Find and edit custom descriptions for officials, quickly look up your team’s most recent engagement with an office, and search through legislative history, staffer information, and more so that you never walk into a room unprepared.",
                videoSrc: "https://www.youtube.com/embed/l0Hno6xX4vI?playlist=l0Hno6xX4vI",
                videoClass: "mobile-xr-video",
                backgroundImgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/mobile/iphone-xr.png"),
                altText: "prep-for-meeting",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "legislative-tracking",
                key: "legislative-tracking",
                textAlignedLeft: false,
                mobilePage: true,
                sectionLeadHeader: "Track Legislation",
                sectionMainHeader: "Legislative Tracking On The Go",
                sectionText: "Quorum's entire legislative tracking system is ready for the road. Take stances on new legislation, view what you’re tracking by category, and write custom descriptions that are saved and shared across your team.",
                videoSrc: "https://www.youtube.com/embed/N5yyWqMgpKk?playlist=N5yyWqMgpKk",
                videoClass: "mobile-xr-video",
                backgroundImgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/mobile/iphone-xr.png"),
                altText: "legislative-tracking",
            },
        },
        {
            component: ProductShow,
            props: {
                id: "business-card-scanner",
                key: "business-card-scanner",
                textAlignedLeft: true,
                mobilePage: true,
                theme: "section-purple",
                sectionLeadHeader: "Save Time for What Matters",
                sectionMainHeader: "Business Card Scanner",
                sectionText: "Have a stack of business cards from your last conference? They only take two taps to add to Quorum—our fully automated business card scanner will create a new contact directly from your phone, complete with address, phone number, custom fields, and more.",
                videoSrc: "https://www.youtube.com/embed/5qIoRwi5Qlg?playlist=5qIoRwi5Qlg",
                videoClass: "mobile-xr-video",
                backgroundImgSrc: generateStaticUrl("apps/marketing-website/images/screenshots/mobile/iphone-xr.png"),
                altText: "business-card-scanner",
            },
        },
    ]
}

export default MOBILE_EU_PRODUCT_VALUES
